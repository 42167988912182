import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivateFn } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { PermissionRolesMap } from '@models/permissions.db';
import { Permissions } from '@models/permissions.enum';
import { ToastrService } from 'ngx-toastr';

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const toastr = inject(ToastrService);
  const permission = route.data['permission'] as Permissions;
  const userRoles = authService.roles;
  const requiredRoles = PermissionRolesMap[permission];

  if (authService.getToken() && requiredRoles.some(role => userRoles.includes(role))) {
    return true;
  }

  const page = route?.url?.length ? (': ' + route.url[0].path) : '';
  router.navigate(['/']);
  toastr.warning(`You do not have permission to access this page${page}`, ' ');

  return false;
};
